import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Form from 'react-bootstrap/Form';
import TestimonialCarousel from "../components/testimonialCarousel/testimonialCarousel";
import Seo from "../components/seo";
import Accordion from '../utils/Accordion';
import { submitActiveCampaignForm } from '../utils/campaign';
import "../styles/style-index.css";

const getdata = graphql`{
  wpgraphql {
    page(id: "cG9zdDozMjQ4") {
      uri
      seo {
        canonical
        metaDesc
        metaKeywords
        title
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphTitle
        opengraphType
        opengraphImage {
          sourceUrl
        }
      }
      homepage {
        banner {
          bannerh2
          bannerh3
          bannerimage {
            title
            altText
            sourceUrl(size: LARGE)
            srcSet(size: LARGE)
          }
        }
        ipProtection {
          ipimage {
            id
            sourceUrl
            slug
          }
        }
        advancedLegaltech {
          description
          heading
          icon {
            id
            sourceUrl
          }
        }
        testimonial {
          heading
          paragraph
          subheading
          testimonialicon {
            id
            title
            sourceUrl(size: MEDIUM_LARGE)
          }
        }
        services {
          heading
          paragraph
          serviceicon
          pagelink {
            ... on WPGraphQL_Page {
              id
              content
            }
          }
        }
        works {
          worksparagraph
          worksheading
          sampleworksimages {
            sourceUrl
            id
          }
          workslink {
            title
            url
          }
        }
      }
    }
  } 
}
`
const HomeNew = () => {
  const data = useStaticQuery(getdata);
  const page = data.wpgraphql.page;
  const common = data.wpgraphql.page.homepage
  const iplogo = data.wpgraphql.page.homepage.ipProtection;
  const advLegal = data.wpgraphql.page.homepage.advancedLegaltech;
  const servicesData = data.wpgraphql.page.homepage.services;
  const testimonialData = data.wpgraphql.page.homepage.testimonial;
  const worksData = data.wpgraphql.page.homepage.works;
  const wData = worksData[0];
  // get width x height of banner image
  var width = null;
  var height = null;
  var bannerImgUrl = common.banner.bannerimage.sourceUrl;
  if (bannerImgUrl) {
    // ex, 1024x683.jpg
    const sizeStr = bannerImgUrl.substr(bannerImgUrl.lastIndexOf('-') + 1);
    if (sizeStr.indexOf('x') > 0) {
      const sizeOnlyStr = sizeStr.substr(0, sizeStr.lastIndexOf('.'))
      const sizeArr = sizeOnlyStr.split('x');
      width = sizeArr[0];
      height = sizeArr[1];
    }
  }
  //
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  };

  const logoResponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 8
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 600, min: 0 },
      items: 1
    }
  };
  // form
  const download3Step = () => {
    const email = document.getElementById('homePageEmail').value;
    const data = { name: email, email: email };
    submitActiveCampaignForm(data);
  }

  const openYoutubeVideo = () => {
    window.open('https://www.youtube.com/watch?v=UdGPRTI4Mx4');
  }

  // get quiz data
  useEffect(() => {
    fetch(`https://api.trustyip.com/quiz-questions`)
      .then((response) => response.json())
      .then((actualData) => {
        localStorage?.setItem('questions', JSON.stringify(actualData.data));
        // remove old anserw
        localStorage?.removeItem('answers');
      })
      .catch((error) => {
        console.log(error)
      });;
  }, []);
  return (
    <>
      <Seo title="Home" description={page.seo.metaDesc} canonical={page.uri} seo={page.seo} />
      <div class="major-margin">
      </div>

      <div id="analyzer" class="analyzer" style={{ marginBottom: 30 + 'px', minHeight: 60 + 'vh' }}>
        <div class="main-a">
          <h2>We make protecting your intellectual property simple</h2>
          <p>Let's see what type of IP protection best fits you</p>
          <a href="/contact">Speak with an Attorney</a>
          <p>
            <a className="bg-white border border-light text-dark" href="/quiz">Take The IP Quiz</a>
          </p>
        </div>
        <div class="main-b">
          <img class="abs" src="./assets/images/sadf-1.webp" alt="" />
          <img class="abs-2" src="./assets/images/Layer-0-1.webp" alt="" />
        </div>
      </div>


      <div>
        <div className="container px-0">
          <div className="row">
            <div className="col-md-12 text-center pt-0">
              <h3 className="mb-5">
                Our lawyers have worked on IP protection for
              </h3>
              <div className="row">
                <Carousel
                  responsive={logoResponsive}
                  autoPlay={true}
                >{iplogo.map(images => (
                  <div className="text-center client-pic mx-auto" key={images.ipimage.id}> <img src={images.ipimage.sourceUrl} alt={images.ipimage.id} loading="lazy" height="45px" width="130px" /></div>
                ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container px-0">
        <div className="row">
          <div className="col-md-6 offset-3 text-center pb-5 pt-0">
            <button onClick={openYoutubeVideo} className="btn btn-warning" style={{ fontSize: '20px', padding: '20px 40px' }}>Click Here To Watch A Client's Interview</button>
            {/* <iframe loading="lazy" width="560" height="315" src="https://www.youtube.com/embed/UdGPRTI4Mx4?si=TRXBXVdTO5GzuyvE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
          </div>
        </div>
      </div>

      <div class="our-services">
        <h2>Our Services &amp; Products</h2>
        <div class="our-services-div">
          {servicesData.map((sData, index) => (
            <div key={index} className="our-services-item">
              <i className={`h1 ${sData.serviceicon}`}></i> 
              <h4 className="h4 my-3">{sData.heading}</h4>
              <p>{sData.paragraph}</p>
              {/* <Link to={sData.button} className="btn btn-danger" role="button" target="_blank">Read More</Link> */}
              <Accordion content={<div dangerouslySetInnerHTML={{ __html: sData.pagelink.content }} />} />
            </div>
          ))}
        </div>
      </div>
      <div className="container px-0" style={{ backgroundImage: 'url(/assets/images/image_2023_09_01T04_49_26_564Z-scaled.webp)' }}>
        <div class="analyzer mx-0 pt-5 px-0" style={{ marginBottom: 0 + 'px' }}>
          <div>
            <img class="abs" style={{ height: 500 + 'px' }} src="./assets/images/ipchecker1.png" alt="" />
            <img class="abs-2" src="./assets/images/image_2023_09_01T05_33_09_154Z.webp" alt="" />
          </div>
          <div class="mb-p ps-4">
            <h2>IPChecker</h2>
            <p style={{ width: 100 + '%' }}>IPChecker helps you identify and create value from your existing and potential intellectual property (IP)
              assets including patents, trademarks, trade secrets and copyrights. These assets can add significant
              value to your venture. Investors can value each patent application at $100,000 or more, depending on the
              industry. For example, even a provisional application for a blockbuster drug can be worth
              multi-billion dollars.
            </p>
            <a href="https://ipcheck.patentpc.com/register" target="_blank">Try IPChecker</a>
          </div>
        </div>
      </div>

      <div class="our-special">
        <div class="special-content">
          <h2>What’s our Special</h2>
          <p>As a full service Intellectual Property Law Firm, we will handle all your IP needs from start to
            finish</p>
        </div>
        <div class="special-services">
          <div class="s-s-items a">
            <img src="./assets//images/legal-document-1.svg" alt="" />
            <h3>Advanced LegalTech</h3>
            <p>We not only understand technology, but we also develop AI computer-aided-designsoftware and
              patent analytics to improve our IP workflow.</p>
          </div>
          <div class="s-s-items b">
            <img src="./assets/images/shopping-1.svg" alt="" />
            <h3>Fixed-Fee Pricing</h3>
            <p>Enabled by technology, our cost-effective flat-fee structure protects you against billing
              surprises.</p>
          </div>
          <div class="s-s-items c">
            <img src="./assets/images/customer-satisfaction-1.svg" alt="" />
            <h3>Personalized Service</h3>
            <p>Get fast, responsive service. Your dedicated lawyer knows your business from the ground up.</p>
          </div>
          <div class="s-s-items d">
            <img src="./assets/images/image-10.svg" alt="" />
            <h3>Trusted Professionals</h3>
            <p>Our patent lawyers and agents came from big law firms and have worked with many Fortune 500
              companies.</p>
          </div>
        </div>
      </div>

      <div class="industries">
        <div class="ind-content">
          <div class="i-image">
            <img src="./assets/images/image_2023_09_01T06_46_31_193Z.webp" alt="" />
          </div>

          <div class="i-btn">
            <div>
              <h2>Industries We Covered</h2>
            </div>

            <div class="i-btn-i"> <span><a href="/blogs/3-d-printing" className="text-white">3-D Printing</a></span>
              <span><a href="/blogs/artificial-intelligence" className="text-white">Artificial Intelligence</a></span>
              <span><a href="/blogs/autonomous-vehicles" className="text-white">Autonomous Vehicles</a></span>
              <span><a href="/blogs/biopharmaceuticals" className="text-white">Biopharmaceuticals</a></span>
              <span><a href="/blogs/blockchain-and-fintech" className="text-white">Blockchain And Fintech</a></span>
              <span><a href="/blogs/communications" className="text-white">Communications</a></span>
              <span><a href="/blogs/digital-healthcare" className="text-white">Digital Healthcare</a></span>
              <span><a href="/blogs/electronics" className="text-white">Electronics</a></span>
              <span><a href="/blogs/internet-of-things" className="text-white">Internet Of Things</a></span>
              <span><a href="/blogs/medical-device" className="text-white">Medical Device</a></span>
              <span><a href="/blogs/nanotechnology" className="text-white">Nanotechnology</a></span>
              <span><a href="/blogs/patent-financing" className="text-white">Patent Financing</a></span>
              <span><a href="/blogs/prescription-&-otc-drugs" className="text-white">Prescription & OTC Drugs</a></span>
              <span><a href="/blogs/quantum-computing" className="text-white">Quantum Computing</a></span>
              <span><a href="/blogs/software" className="text-white">Software</a></span>
            </div>

          </div>
        </div>
      </div>

      <div class="case-study">
        <div class="case-s">
          <div class="case-s-items items-1">
            <h2 class="o">Case Studies</h2>
            <h2 class="tw">{wData.worksheading}</h2>
            <h2 class="thr">{wData.workslink.title}</h2>
            <div>{wData.worksparagraph}</div>
            <a href={wData.workslink.url} target="_blank">View All Details</a>
          </div>
          <div class="case-s-item items-2">
            <img class="imgone" src="./assets/images/image_2023_09_01T10_16_47_153Z.webp" alt="" />
            <img class="imgtwo" src="./assets/images/image_2023_09_01T10_16_53_553Z.webp" alt="" />
            <img class="imgthree" src="./assets/images/image_2023_09_01T10_15_44_428Z.webp" alt="" />

          </div>
        </div>

      </div>

      <div class="test-major">
        <h2 class="first">Testimonials</h2>
        <h2 class="second">People are talking</h2>
        <div class="row">
          <div className="col-md-12 testimonials">
            <Carousel responsive={responsive} autoPlay={false}>
              {
                testimonialData.map((tData, index) => {
                  return (
                    <div class="test-items">
                      <img src="./assets/images/stars-1.png" alt="" />
                      <p>{tData.paragraph}</p>
                      <span class="head">{tData.heading}</span>
                      <span class="foot">{tData.subheading}</span>
                    </div>
                  )
                })}
            </Carousel>
          </div>
        </div>
      </div>

      <div className="container px-0" style={{ marginBottom: '100px' }}>
        <div className="row">
          <div className="col-md-6 offset-3 text-center pb-5 pt-0">
            <video style={{ minHeight: 275 + 'px', width: 100 + '%' }} preload="metadata" poster="/assets/images/contact-thumb.png" controls>
              <source src="https://wp.patentpc.com/wp-content/uploads/2023/12/3-step-increase-valuation.mp4" type="video/mp4"></source>
            </video>
          </div>
        </div>
      </div>

      <div class="Ready">
        <div class="r-l">
          <h2 style={{ marginTop: 20 + "px" }}>Ready to get started?</h2>
          {/* <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered
            alteration in some form, by injected humour, or randomised words which don’t look even slightly
            believable.</p> */}
        </div>
        <div class="r-r">
          <a href="https://calendly.com/powerpatent/patentpc" target="_blank">Speak With An Attorney</a>
          <img src="./assets/images/image_2023_09_01T09_40_19_429Z.png" alt="" />
        </div>


      </div>
    </>
  )
}
export default HomeNew
